@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  user-select: none;
}

html {
  font-size: 15px;
}
@media (max-width: 1700px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 1545px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 1400px) {
  html {
    font-size: 12px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 10px;
  }
}
:root {
  --app-height: 100%;
}
body {
  height: 100vh;
  height: var(--app-height);
}
#root {
  width: 100%;
  height: 100%;
}

.start {
  opacity: 0;
}
.start.end {
  opacity: 1;
}

.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}

@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite,
    colors 5.6s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@-webkit-keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.animate-up-down {
  animation: up-down 1.5s ease-in-out infinite;
}

@keyframes up-down {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

input::-webkit-slider-thumb {
  appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e5e7eb;
}

input::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e5e7eb;
}

input::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e5e7eb;
}

